'use client';

import { models } from '@components/cms/models';
import {
    AdhesePosition,
    getAdheseSlots,
} from '@components/features/advertisements/helpers/slots';
import { AdvertisementProvider } from '@components/features/advertisements/provider';
import {
    AllerhandePage,
    MAX_CONTAINER_WIDTH,
} from '@components/layouts/allerhande-page/allerhande-page';
import { BreadcrumbsProvider } from '@contexts/breadcrumbs';
import type { ContentCmsPageQuery } from '@graphql/_generated-operation-types';
import type { Breadcrumb } from '@interfaces/breadcrumb';
import { useCMS } from '@royalaholddelhaize/ah-next-core/src/cms/client';
import { useConsent } from '@royalaholddelhaize/ah-next-core/src/consent/client';
import { useHost } from '@royalaholddelhaize/ah-next-core/src/host/client';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { SpotlightAHRMS } from '@royalaholddelhaize/ah-ui-ads';
import { SpotlightLayoutContext } from '@royalaholddelhaize/ah-ui-ads/src/spotlight-ahrms/spotlight-ahrms.interface';
import {
    ContentBlock,
    ContentBlockPreview,
} from '@royalaholddelhaize/ah-ui-cms';
import { PagePreviewData } from '@royalaholddelhaize/ah-ui-cms/src/components/page-meta-data/page-preview-data';
import type { ContentPage } from '@royalaholddelhaize/ah-ui-cms/src/interfaces';
import { Container } from '@royalaholddelhaize/design-system-pantry-web/components/layout/container/container';
import { useParams } from 'next/navigation';
import type { FC } from 'react';

import css from './cms-page.module.scss';

export type CMSPageProps = {
    data: ContentCmsPageQuery['contentAllerhandeCMSPage'];
};

export const CMSPage: FC<CMSPageProps> = ({ data }) => {
    const { slug } = useParams<{ slug?: string | string[] }>();
    const { isPreview } = useCMS();
    const { domain } = useHost();
    const { consent } = useConsent();
    const { locale } = useTranslations();

    if (!data) {
        return null;
    }

    const ContentComponent = isPreview ? ContentBlockPreview : ContentBlock;

    const breadcrumbs = data?.parents.map<Breadcrumb>(parent => ({
        name: parent.name,
        href: parent.link,
    }));

    const isHomePage = !slug || slug.length === 0;
    const isRecipeGroup = slug && slug.length > 1 && slug[0] === 'recepten';

    let adPosition = undefined;
    if (isHomePage) {
        adPosition = AdhesePosition.HOMEPAGE;
    } else if (isRecipeGroup) {
        adPosition = AdhesePosition.RECIPE_GROUP;
    }

    return (
        <AdvertisementProvider position={adPosition}>
            <BreadcrumbsProvider value={breadcrumbs || []}>
                {data.previewData && (
                    <PagePreviewData previewData={data.previewData} />
                )}

                <AllerhandePage hasContainer={false} className={css.page}>
                    <ContentComponent
                        isAdConsent={Boolean(consent && consent?.ad)}
                        isSocialConsent={Boolean(consent && consent?.social)}
                        models={models}
                        locale={locale}
                        content={
                            {
                                ...data,
                                components: [
                                    {
                                        ...data.components[0],
                                        items: [data.components[0].items[0]],
                                    },
                                ],
                            } as unknown as ContentPage
                        }
                    />

                    {adPosition && (
                        <Container maxWidth={MAX_CONTAINER_WIDTH}>
                            <SpotlightAHRMS
                                slots={getAdheseSlots(domain, adPosition)}
                                layout={SpotlightLayoutContext.FULL}
                            />
                        </Container>
                    )}

                    <ContentComponent
                        isAdConsent={Boolean(consent && consent.ad)}
                        isSocialConsent={Boolean(consent && consent.social)}
                        models={models}
                        locale={locale}
                        content={
                            {
                                ...data,
                                components: [
                                    {
                                        ...data.components[0],
                                        items: data.components[0].items.slice(
                                            1,
                                        ),
                                    },
                                ],
                            } as unknown as ContentPage
                        }
                    />
                </AllerhandePage>
            </BreadcrumbsProvider>
        </AdvertisementProvider>
    );
};
