import { MemberMeatPreferencesOptionType } from '@graphql/_generated-operation-types';

export type DIET_TYPES_NLD =
    | 'VEGETARISCH'
    | 'PESCOTARISCH'
    | 'VEGANISTISCH'
    | 'FLEXITARISCH';

export const DIET_TYPES_FROM_NLD: Record<
    DIET_TYPES_NLD,
    MemberMeatPreferencesOptionType
> = {
    VEGETARISCH: MemberMeatPreferencesOptionType.VEGETARIAN,
    PESCOTARISCH: MemberMeatPreferencesOptionType.PESCATARIAN,
    VEGANISTISCH: MemberMeatPreferencesOptionType.VEGAN,
    FLEXITARISCH: MemberMeatPreferencesOptionType.FLEXITARIAN,
};

export const DIET_TYPES_TO_NLD: Record<
    MemberMeatPreferencesOptionType,
    DIET_TYPES_NLD
> = {
    [MemberMeatPreferencesOptionType.VEGETARIAN]: 'VEGETARISCH',
    [MemberMeatPreferencesOptionType.PESCATARIAN]: 'PESCOTARISCH',
    [MemberMeatPreferencesOptionType.VEGAN]: 'VEGANISTISCH',
    [MemberMeatPreferencesOptionType.FLEXITARIAN]: 'FLEXITARISCH',
};
