import { LabeledLink } from '@components/elements/labeled-link/labeled-link';
import { RichContent } from '@components/elements/rich-content/rich-content';
import { Breadcrumbs } from '@components/features/breadcrumbs/breadcrumbs';
import { ImageSheet } from '@components/features/image-sheet/image-sheet';
import { pageAnchorOnItemClick } from '@helpers/anchor';
import { useContentBreadcrumbs } from '@hooks/use-content-breadcrumbs';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    RECIPE_GROUP_HEADER_LANE,
    RECIPE_GROUP_HEADER_LANE_LINK,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC, KeyboardEvent, MouseEvent } from 'react';
import type { RecipeGroupHeader } from './component.interface';
import { PageAnchors } from './elements/page-anchors/page-anchors';
import css from './recipe-group-header-lane.module.scss';

export const RecipeGroupHeaderLane: FC<RecipeGroupHeader> = ({
    title,
    description,
    anchors = [],
    recipe,
    image,
}) => {
    const breadcrumbs = useContentBreadcrumbs();
    const onAnchorClickCallback = (
        event: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>,
    ) => {
        pageAnchorOnItemClick(event);
    };

    return (
        <section
            className={css.root}
            {...createTestHook(RECIPE_GROUP_HEADER_LANE)}
        >
            <ImageSheet
                alt={title}
                images={image?.variants || recipe?.images}
                mediaChildren={
                    recipe?.href && (
                        <div
                            {...createTestHook(RECIPE_GROUP_HEADER_LANE_LINK)}
                            className={css.recipeLinkContainer}
                        >
                            <LabeledLink
                                href={recipe?.href}
                                className={css.recipeLink}
                                target="_self"
                            >
                                {recipe.title}
                            </LabeledLink>
                        </div>
                    )
                }
            >
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <Typography
                    className={css.recipeGroupHeaderLaneTitle}
                    variant="display"
                    as="h1"
                >
                    {title}
                </Typography>

                <RichContent html={description} />

                {Boolean(anchors.length) && (
                    <PageAnchors
                        anchors={anchors.slice(0, 6)}
                        onClickItem={onAnchorClickCallback}
                    />
                )}
            </ImageSheet>
        </section>
    );
};
