import type { FC } from 'react';
import type { ContentPreviewData } from '../../interfaces';
import {
    PAGE_META_DATA_ID,
    addBodyComments,
} from '../helpers/preview-comments';

type PagePreviewDataProps = {
    previewData?: ContentPreviewData | null;
};

export const PagePreviewData: FC<PagePreviewDataProps> = ({ previewData }) => (
    <div
        id={PAGE_META_DATA_ID}
        ref={element => {
            // add required hippo page meta data for preview mode
            const comment = `${previewData?.begin || ''}${
                previewData?.end || ''
            }${previewData?.contentLink || ''}`;
            addBodyComments(element, PAGE_META_DATA_ID, comment);
        }}
    />
);
