import { DIET_TYPES_TO_NLD } from '@constants/food-preferences';
import { AllerhandeRoute } from '@enums/route';
import type { MemberMeatPreferencesOptionType } from '@graphql/_generated-operation-types';
import { useMemberPreferences } from '@graphql/member-preferences/member-preferences-hook';
import { useAuthentication } from '@royalaholddelhaize/ah-next-core/src/authentication/client';
import { LoginState } from '@royalaholddelhaize/ah-next-core/src/enums/login-state';
import type { MemberMeatPreferenceOption } from '@royalaholddelhaize/ah-ui-recipe-card/src/graphql/_generated-graphql-types';
import { usePathname } from 'next/navigation';

interface UseFoodPreferencesSelection {
    preferences: MemberMeatPreferenceOption[];
    onFoodPreferenceClick: (selection: MemberMeatPreferencesOptionType) => void;
    isLoading: boolean;
    isLoggedOut: boolean;
}

export const useFoodPreferencesSelection = (): UseFoodPreferencesSelection => {
    const { state, memberId } = useAuthentication();
    const isLoggedOut = state === LoginState.ANONYMOUS || !memberId;
    const pathname = usePathname();
    const { preferences, loading } = useMemberPreferences(isLoggedOut);

    const redirectToFoodPreferences = (
        selection: MemberMeatPreferencesOptionType,
    ) => {
        // We do this because the values coming from the API are in in english, but the URLs in Dutch.
        // We also need to support the mobile App URLs, these are in Dutch for food-preferences page.
        let dietType = '';
        if (selection in DIET_TYPES_TO_NLD) {
            dietType = DIET_TYPES_TO_NLD[selection];
        }

        window.location.href = `${AllerhandeRoute.FoodPreferences}?dieet=${encodeURIComponent(
            dietType,
        )}&webRedirect=${encodeURIComponent(pathname)}`;
    };

    return {
        preferences: preferences?.meatPreferences || [],
        onFoodPreferenceClick: redirectToFoodPreferences,
        isLoading: loading,
        isLoggedOut,
    };
};
